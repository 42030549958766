@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import "../../../variables.scss";

.about-us-section-three{
    .text-section{
        width: 100%;
        height: 100%;
        background-color:rgba(196, 196, 196, 0.15);
        padding-right: 1rem;
        padding-left: 1rem;
        padding-top:1rem;
        padding-bottom:1rem;
        @include media-breakpoint-up(lg) {
          padding-right: 5rem;
          padding-left: 5rem;
        }

        h3, p {
            z-index: 2;
        }
    }
}