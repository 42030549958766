.amchart {
  
  #chartdiv {
    width: 100%;
    height: 500px;
    max-width: 100%;
  }
  
  #controls {
    overflow: hidden;
    padding-bottom: 3px;
  }
  
  .amcharts-range-selector-range-wrapper {
    display: none;
  }

  .amcharts-range-selector-top .amcharts-range-selector-period-wrapper, .amcharts-range-selector-bottom .amcharts-range-selector-period-wrapper {
    float: none;
  }
  
  .amcharts-range-selector-period-button, .amcharts-range-selector-period-button:focus {
    background-color: #555c89;
    border-color: transparent;
    color: #fff;
    border-radius: 0.25rem;
  }
  
  .amcharts-range-selector-period-button:hover {
    background-color: #dd484c;
    border-color: transparent;
    color: #fff;
  }
}
