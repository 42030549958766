@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import "../../../variables.scss";
.hero {
  background: rgb(55, 63, 104);
  background: linear-gradient(
    135deg,
    rgba(55, 63, 104, 1) 0%,
    rgba(10, 17, 62, 1) 100%
  );
  
  height: 100%;
  min-height: 590px;
  @include media-breakpoint-up(lg) {
    height: 590px;
    background-image: url("../../../assets/images/hero.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .call-to-action {
    margin-top: 6rem;
    margin-bottom: 4rem;
  }
}
