@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import "../../../variables.scss";
.report-hero {
  height: 410px;

  background-image: url("../../../assets/images/reports.png");

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @include media-breakpoint-up(lg) {
    background-size: contain;
  }
  .container {
    // background: linear-gradient(90deg, rgba(55,63,104,1) 0%, rgba(0,212,255,0) 100%);
    background: rgb(55, 63, 104);
    background: -moz-linear-gradient(
      90deg,
      rgba(55, 63, 104, 1) 0%,
      rgba(86, 93, 132, 0) 100%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(55, 63, 104, 1) 0%,
      rgba(86, 93, 132, 0) 100%
    );
    background: linear-gradient(
      90deg,
      rgba(55, 63, 104, 1) 0%,
      rgba(86, 93, 132, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#373f68",endColorstr="#565d84",GradientType=1);
  }
  .left-arrow {
    right:0px;
    top:0px;
  }
}
