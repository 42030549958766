@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600&display=swap');
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
.report-body {
    font-family: 'Open Sans';
    margin-top:3rem;
    h2 {
        font-weight: 600;
    }
    .report-header {
        color:#dd484c !important;
        line-height: 164%;
        @include media-breakpoint-up(lg) {
            max-width: 80%;
        }
    }

    .intro-table{
        thead{
            background-color: #565D84;
            color: white;
        }

        tbody {
            tr {
                td {
                    text-align: right;
                }
            }
        }
        thead {
            tr {
                th:nth-child(n+2){
                    text-align: right;
                }
            }
        }
        thead{
            th{
                padding-left: 0.25rem;
                padding-right: 0.25rem;
            }
        }
        td, th {
            padding: .1rem;
        }
    }
    .main-section{
        text-align: left;
        p {
            text-align: justify;
        }
    }

    .list-section {
        text-align: justify;
        
    }

    .seperator {
        border-bottom:1.5px solid #dd484c ;
    }

    .seperator-top-xtra {
        border-top:2px solid #dd484c ;
    }

    .seperator-row {
        td, th {
            border-bottom: 1.5px solid #dd484c;
        }
    }

    .text-blue {
        color: #5D68B0;
    }

    .text-light-grey {
        color:#414042 
    }

    p {
        color:#414042;
        line-height: 164%;
    }

    .report-sub-header{
        font-size: 1.2rem;
        line-height: 164%;
    }

    caption {
        padding-top: 0.25rem;
        padding-bottom: 0;
    }

    @media (min-width: 768px){
        .image-half-width {
            float: left;
            margin-right: 1rem;
            max-width: 50%;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem !important;
        }
    }
}