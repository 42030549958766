.table-of-contents {
    text-align:left;
    .toc-header {
        color: #DD484C;
    }
    .toc-header-accordian { 
        color: #fff;
    }
    li {
        .nav-link{
            padding: 0.1rem 1rem;
        }
    }
}
