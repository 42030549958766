@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import "../../variables.scss";

.footer-section {
  // min-height: 531px;
  padding-bottom: 1rem;
  margin-bottom: 0;
  background: rgb(55, 63, 104);
  background: linear-gradient(
    135deg,
    rgba(55, 63, 104, 1) 0%,
    rgba(10, 17, 62, 1) 100%
  );

  @include media-breakpoint-up(lg) {
    // min-height: 531px;
    background-image: url("../../assets/images/footer.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .active{
    color:#dd484c !important;
  }
  .icon {
    height: 26px;
    margin-right: 1rem;
    
  }

  .address{
    white-space: pre-line;
  }

  .tnc {
    font-size: 0.8rem;
  }

  .disclaimer {
    font-size: 0.8rem;
  }

  .copyright {
    font-size: 0.8rem;
  }

  .disabled{
    color: grey;
    pointer-events: none;
  }
}
