@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import "../../../variables.scss";
.our-services-section-four {
  .banner {
    height: 340px;
    background-image: url("../../../assets/images/our-services-section-four.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include media-breakpoint-up(lg) {
      background-size: contain;
    }
  }

  

  .list-container{
    
    .list-heading{
      @include media-breakpoint-up(lg) {
        padding-left: 3rem;
        padding-right: 3rem;;
      }
    }
    p,li,h3 {
      z-index: 2;
    }

    .right-arrow{
      z-index: 1;
    }

    li{
      margin-top:1rem;
    }
  
    ul {
      margin-bottom: 2rem;
    }
  }

  
}
