@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import "../../variables.scss";

.navbar {
  z-index: 100;
  .dropdown-item {

  }
  .nav-link,.dropdown-item {
    color: #373f68 !important;
    &.active {
      color: #dd484c !important;
      background-color: white;
    }
  }


  .nav-language-selector{
    @include media-breakpoint-up(lg) {
      margin-left: 1.5rem !important;
    }
  }

  .disabled{
    color: rgba(0,0,0,.3)!important;
  }

  .navbar-toggler {
    color: #373f68;
    border-color: transparent;
    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,%3Csvg width='25' height='22' viewBox='0 0 25 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline y1='1' x2='25' y2='1' stroke='%23373F68' stroke-width='2'/%3E%3Cline y1='11' x2='25' y2='11' stroke='%23373F68' stroke-width='2'/%3E%3Cline y1='21' x2='25' y2='21' stroke='%23373F68' stroke-width='2'/%3E%3C/svg%3E%0A");

      &.close-icon {
        background-image: url("data:image/svg+xml,%3Csvg width='22' height='20' viewBox='0 0 22 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='2.70711' y1='1.29289' x2='20.3848' y2='18.9706' stroke='%23373F68' stroke-width='2'/%3E%3Cline x1='1.29289' y1='19.2929' x2='18.9706' y2='1.61522' stroke='%23373F68' stroke-width='2'/%3E%3C/svg%3E%0A");
      }
    }
  }

  .btn-link {
    color: rgba(41, 41, 41, 0.3);
    &.active {
      font-weight: bold;
      color: #000;
      &::after {
        content: "";
        position: absolute;
        width: 10px;
        height: 1px;
        background: #dd484c;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
}
