@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import '~animate.css/animate.min.css';
html {
  height: 100%;
}
body {
  height: 100%;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:#373F68;
}

#root, .App {
  height: 100%;
}