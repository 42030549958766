.about-us-section-two{
    padding-top:5rem;
    padding-bottom:5rem;
    h1{
        line-height: 1.5;
    }

    p {
        line-height: 2;
    }
}