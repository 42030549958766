@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import "../../../variables.scss";
.about-us-section-five {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-image: url("../../../assets/images/about-us-section-five.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
