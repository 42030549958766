@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600&display=swap");
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";

.company-profile-hero {
  height: 240px;
  background-image: url("../../../assets/images/hero.svg");
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  
  .container {
    text-align: left;
  }

  .company-profile-hero-text{
    background-color: #fff;   
    height: 40%;
  }
}

.company-profile-body {

  h2 {
    font-weight: 600;
    text-transform: uppercase;
  }

  .border-top-grey {
    border-top: 1.5px solid #ddd;
  }

  .border-bottom-grey {
    border-bottom: 1.5px solid #ddd;
  }

  .nav-tabs {
    border-bottom: none;
  }

  .nav-link {
    color: #555c89;
    text-align: left !important;
    padding: 0.5rem 1rem 0.5rem 0;
  }

  .nav-link.always-active,
  .nav-link:hover {
    font-weight: bold;
    border-bottom: 4px solid #373f68 !important;
  }

  .nav-link.active,
  .nav-link:hover {
    border-left-color: #fff;
    border-top-color: #fff;
    border-right-color: #fff;
    color: #555c89;
    border-bottom: 1px solid #dbdbdb;
  }

  #basic-info {
    border: 1px solid #dbdbdb;
    background-color: #fafafa;
  }

  #basic-info > div {
    padding-right: 2rem;
    padding-left: 2rem;
    @include media-breakpoint-up(lg) {
      padding-right: 8rem;
      padding-left: 2rem;
    }
  }

  .filingsIcon {
    width: 28px;
  }

  .btn-outline-primary > .translateIcon {
    height: 20px;
    display: inline-block !important;
    margin-left: 5px;
  }

  .table-shareholders > thead th {
    border-top: none;
  }

  .basic-info-table > :first-child th,
  .basic-info-table > :first-child td {
    border-top: none;
  }

  .filings-container {
    max-height: 400px;
    overflow-y: auto;
    display: block;
  }

  .filings-table > :first-child th,
  .filings-table > :first-child td {
    border-top: none;
  }

  .efr-reports-table > :first-child th,
  .efr-reports-table > :first-child td {
    border-top: none;
  }

  .filings-table, .efr-reports-table {    
      tbody {
        text-align: left;
        tr {
          @media (min-width: 768px) {
            td:nth-child(2) {
              width: 300px;
            }
          }
        }
      }
  }

  #efr-reports a.btn {
    color: white;
    border-color: #555c89;
    background-color: #555c89;

    img {
      display: none;
    }
  }

  .basic-info-table > tbody > tr {
    border-bottom: 1px solid #ddd;
  }

  .basic-info-table > tbody > tr:last-child {
    border-bottom: none;
  }

  .section-header {
    color: #dd484c !important;
    line-height: 164%;
    @include media-breakpoint-up(lg) {
      max-width: 320px;
    }
  }

  .seperator {
    border-bottom: 1.5px solid #dd484c;
  }

  h5 {
    font-weight: 600;
  }

  #company-filings a.btn {
    color: white;
    border-color: #555c89;
    background-color: #555c89;

    img {
      display: none;
    }
  }

  .table-meta {
    font-size: 0.6rem;
    @include media-breakpoint-up(md) {
      font-size: 1rem;
    }
  }
  .custom-tables {
    font-size: 0.8rem;
    thead {
      background-color: #565d84;
      color: white;
      text-align: left;
      tr {
        @media (min-width: 768px) {
          th:first-child {
            min-width: 300px;
          }
        }
        th:nth-child(n + 2) {
          white-space: nowrap;
        }
      }
    }

    tbody {
      tr {
        td:first-child {
          text-align: left;
          min-width: 14rem;
          width: 100%;
          @include media-breakpoint-up(md) {
            min-width: unset;
          }
        }
        td:nth-child(n + 2) {
          text-align: center;
        }
      }
    }

    .seperator {
      border-bottom: 1.5px solid #dd484c;
    }

    .seperator-xtra {
      border-bottom: 2px solid #dd484c;
    }

    .seperator-row {
      td {
        border-bottom: 1.5px solid #dd484c;
      }
    }
    .highlight-last-rows {
      tr {
        td:nth-child(n + 5) {
          color: #4472c4;
        }
      }
    }
  }

  .table-striped {
    tbody {
      tr:nth-of-type(odd) {
        background-color: rgb(205, 216, 216) !important;
      }
    }
  }
  .seperator-top {
    border-top: 2px solid #dd484c;
  }

  .container-grey {
    background-color: rgb(240, 240, 240);
  }

  .seperator {
    border-bottom: 1.5px solid #dd484c;
  }

  .seperator-top-xtra {
    border-top: 2px solid #dd484c;
  }

  .text-blue {
    color: #5d68b0;
  }

  .text-light-grey {
    color: #414042;
  }

  .coverage-card {
    background-color: #373f68;
    img {
        max-height: 150px;
    }
    .card-text{
        font-size: 1.5rem;
    }
  }

  .report-tagline {
      color: #dd484c!important;
      line-height: 164%;
  }

  .report-synopsis {
    font-weight: 500;
  }
}
