@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import "../../../variables.scss";

.homepage-info {
  background: linear-gradient(
    47deg,
    rgba(249, 249, 250, 1) 49%,
    rgba(255, 255, 255, 1) 49%
  );
  color: $secondary-color;
  padding-top: 5rem;
  padding-bottom: 5rem;

  .diamond-img{
    z-index: 1;
  }

  p {
    z-index: 2;
  }
  .heading_one {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 200px;
      height: 3px;
      background: rgba(#6879ce, 0.2);
      bottom: -10px;
      left: 0;
      margin: auto;
    }
  }

  .heading_two {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 200px;
      height: 3px;
      background: rgba(#6879ce, 0.2);
      bottom: -10px;
      left: 0;
      margin: auto;
    }
  }
  .row {
    margin-right: 0px;
    margin-left: 0px;
  }
  // .section {
  //   @include media-breakpoint-up(lg) {
  //     margin: 2rem 0 2rem 0;
  //   }
  // }

  .icon-collection {
    margin-top: 1rem;
    margin-bottom: 1rem;
    @include media-breakpoint-up(lg) {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
  }

  .icon {
    height: 48px;
  }

  .apply-padding {
    padding-right: 1rem;
    padding-left: 1rem;
    @include media-breakpoint-up(lg) {
      padding-right: 3rem;
      padding-left: 3rem;
    }
  }
}
