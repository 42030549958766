@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import "../../../variables.scss";
.terms-and-conditions-hero {
  height: 410px;

  background-image: url("../../../assets/images/purple.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @include media-breakpoint-up(lg) {
    background-size: contain;
  }

  .left-arrow {
    margin-top: 5%;
  }
}
