@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import "../../../../variables.scss";

.table-meta{
  font-size: 0.6rem;
  @include media-breakpoint-up(md) { 
    font-size: 1rem;
  }
}
.custom-tables {
  font-size: 0.80rem;
  thead {
    background-color: #565d84;
    color: white;
    text-align: left;
    tr {
      @media (min-width: 768px) {
        th:first-child {
          min-width: 300px;
        }
      }
      th:nth-child(n + 2) {
        white-space: nowrap;
      }
    }
  }

  tbody {
    tr {
      td:first-child {
        text-align: left;
        min-width: 14rem;
        width: 100%;
        @include media-breakpoint-up(md) { 
          min-width: unset;
         }
      }
      td:nth-child(n + 2) {
        text-align: center;
      }
    }
  }

  .seperator {
    border-bottom: 1.5px solid #dd484c;
  }

  .seperator-xtra {
    border-bottom: 2px solid #dd484c;
  }

  .seperator-row {
    td {
      border-bottom: 1.5px solid #dd484c;
    }
  }
    .highlight-last-rows  {
      tr {
        td:nth-child(n + 5) {
          color: #4472c4;
        }
      }
    }
  
}

.table-striped {
  tbody {
    tr:nth-of-type(odd) {
      background-color: rgb(205, 216, 216) !important;
    }
  }
}
.seperator-top {
  border-top: 2px solid #dd484c;
}

.container-grey {
  background-color: rgb(240, 240, 240);
}
