@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import "../../../variables.scss";
.about-us-section-four {
  padding-top: 3rem;
  padding-bottom: 3rem;
  @include media-breakpoint-up(lg) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
