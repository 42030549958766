@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import "../../../variables.scss";
.about-us-hero{
    height: 410px;

  background-image: url("../../../assets/images/about-us-hero.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  // @include media-breakpoint-up(lg) {
  //   background-size: contain;
  // }
}