@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import "../../../variables.scss";
.about-us-section-eight {
  height: 700px;
  @include media-breakpoint-up(lg) {
    height: 483px;
  }
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-image: url("../../../assets/images/about-us-section-eight.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
