@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import "../../../variables.scss";

.homepage-our-services {
  // margin-top:5rem;
  padding-bottom: 2rem;
  background-image: url("../../../assets/images/homepage-services.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: left;
  @include media-breakpoint-up(lg) {
    text-align: center;
  }
  .icon {
    height: 42px;
    margin-right: 1rem;
  }
  .questions-padding {
    @include media-breakpoint-up(lg) {
      padding-right: 3rem;
      padding-left: 3rem;
    }
  }
  .apply-padding {
    @include media-breakpoint-up(lg) {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }
  }
}
