@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import "../../../variables.scss";
.our-services-faq {
  .banner {
    height: 340px;
    background-image: url("../../../assets/images/our-services-faq.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include media-breakpoint-up(lg) {
      background-size: contain;
    }
  }

  .card{
    border-bottom: 1px solid rgba(93, 104, 176, 0.5) !important;
    white-space: pre-wrap;
    .card-body{
        text-align: left;;
    }
  }
}
