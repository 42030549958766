@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import "../../../variables.scss";

.homepage-subscribe {
  height: 277px;
  background-image: url('../../../assets/images/homepage-subscribe-mobile.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position:center;
  @include media-breakpoint-up(lg) {
    background-image: url('../../../assets/images/homepage-subscribe.svg');
  }
  .input-group{
      max-width: 484px;
  }
  .form-control {
    min-width: 250px;
  }
}
